div[data-module='video-snippet'] {
  position: relative;
  width: 100%;
}

div[data-module='video-snippet'] > div:first-of-type {
  height: 100% !important;
  left: 50%;
  position: absolute !important;
  top: 50%;
  width: 100% !important;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.video-snippet-wrapper {
  overflow: hidden;
}

.gobrain-id-custom-controls-container .custom-controls-floating-share {
  box-sizing: content-box;
}

.gobrain-id-custom-controls-container
  .custom-controls-floating-sharebox
  .action-item
  img,
.gobrain-id-custom-controls-container
  .custom-controls-floating-sharebox
  #embed
  .share-embed-input {
  margin: 0;
  width: auto;
  min-width: 0;
}

.gobrain-id-custom-controls-container
  .custom-controls-floating-sharebox
  .clipboard-text {
  padding: 2px;
  font: initial;
  min-height: 0;
}
